import { Grid, Skeleton } from '@mui/material';

export function LoadingRecord() {
  return (
    <Grid container p={2} spacing={2}>
      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
        <Skeleton variant='rounded' width='60%' height={32}></Skeleton>
      </Grid>
      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
        <Skeleton variant='rounded' width='100%' height={24}></Skeleton>
      </Grid>
      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
        <Skeleton variant='rounded' width='30%' height={24}></Skeleton>
      </Grid>
      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
        <Skeleton variant='rounded' width='80%' height={24}></Skeleton>
      </Grid>
      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
        <Skeleton variant='rounded' width='10%' height={24}></Skeleton>
      </Grid>
      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
        <Skeleton variant='rounded' width='60%' height={24}></Skeleton>
      </Grid>
      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
        <Skeleton variant='rounded' width='50%' height={24}></Skeleton>
      </Grid>
      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
        <Skeleton variant='rounded' width='20%' height={24}></Skeleton>
      </Grid>
    </Grid>
  );
}
