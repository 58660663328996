import { Header } from '../components/Header';
import { Footer } from '../components/Footer';
import LANDING_TITLE from '../static/placeholder/landing_title.png';
import LANDING_FOOTER from '../static/placeholder/landing_footer_logo.png';
import BACKGROUND from '../static/placeholder/background.png';
import Stack from '@mui/material/Stack';
// import Skeleton from '@mui/material/Skeleton';
import CardMedia from '@mui/material/CardMedia';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export function LandingPage() {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        width: '100%',
        minHeight: '100vh',
        backgroundColor: '#7d0812',
        backgroundImage: `url(${BACKGROUND})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      <Stack
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          width: '100%',
          height: 'auto',
          overflow: 'auto',
          paddingTop: '2rem',
        }}
        spacing={1}
      >
        <Header />
        {/* <Skeleton variant='rounded' width='inherit' height='60vh'></Skeleton> */}
        <CardMedia
          sx={{
            objectFit: 'contain',
            alignItems: 'center',
            width: '40vw',
            maxWidth: { xs: 250, md: 600 },
            paddingTop: '8rem',
          }}
          component='img'
          image={LANDING_TITLE}
          alt='Default'
        ></CardMedia>
        <Typography sx={{ fontSize: 'larger', color: '#fff', lineHeight: '2.5rem', padding: '2rem 4rem' }}>
          အမျိုးသားညီညွတ်ရေးအစိုးရ၊ ကာကွယ်ရေးဝန်ကြီးဌာနမှ<br></br> မှတ်တမ်းတင်ဂုဏ်ပြုထားသော<br></br>
          မှတ်တမ်းလွှာများနှင့် ပြည်သူ့တော်လှန်ရေးထောက်ပို့မိသားစု (PRF) ကဒ်များအား <br></br>
          အများပြည်သူမှ စစ်ဆေးအတည်ပြုနိုင်ရန် ဖြစ်ပါသည်။
        </Typography>
        <CardMedia
          sx={{ objectFit: 'contain', alignItems: 'center', width: '6vw', paddingBlock: '8rem' }}
          component='img'
          image={LANDING_FOOTER}
          alt='Default'
        ></CardMedia>
        <Footer />
      </Stack>
    </Box>
  );
}
