import MOD_LOGO from '../static/logo/MOD-LOGO.png';
import PRF_LOGO from '../static/logo/PRF_LOGO_DARK.png';
import PDF_LOGO from '../static/logo/PDF_FLAG.png';
import { logger } from '@sentry/utils';
import { Footer } from '../components/Footer';
import { MemberFound } from '../components/Member/MemberFound';
import { getRecordByUuidTemplate, openInNewTab } from '../services/Api';
import { makeStyles } from '@mui/styles';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { LoadingMember } from '../components/Member/LoadingMember';
import { MemberNotFound } from '../components/Member/MemberNotFound';
import { SupportCalendar } from '../components/Member/SupportCalendar';
import { Box, Stack, Paper, CardMedia, Typography } from '@mui/material';

const useStyles = makeStyles(() => ({
  headerLogo: {
    cursor: 'pointer',
    maxHeight: '4rem'
  }
}));

export function MemberVerifyPage() {
  const classes = useStyles();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const testing = queryParams.get('tt');
  const memberid = queryParams.get('mbid');
  const uuid = queryParams.get('uuid');
  const prjTp = queryParams.get('prjTp');
  const [isLoading, setIsLoading] = useState(false);
  const [record, setRecord] = useState([]);
  const [validQuery, setValidQuery] = useState(false);
  const [hasRec,setHasRec] = useState(false);

  useEffect(() => {
    if (uuid && prjTp) {
      getData();
    } else {
      setValidQuery(false);
      setIsLoading(false);
    }
  }, [uuid, prjTp]);

  useEffect(() => {
    setHasRec(true);
  },[record]);

  const sampleData = () => {
    let data =[
      {
        "member_id": 1234, 
        "member_name": "Tester Chris", 
        "transactions": [
          {
            "id": 2840,
            "fundraiser_id": 3, 
            "contribution_date": "2021-12-15",
            "valid_thru": "2021-12-31",
            "amout": 333333.3333333333,
            "currency_iso": "WON",
            "ofp_usd_est": 238.09666666666666
          },
          {
            "id": 2841,
            "fundraiser_id": 3, 
            "contribution_date": "2021-12-15",
            "valid_thru": "2022-01-31",
            "amout": 333333.3333333333,
            "currency_iso": "WON",
            "ofp_usd_est": 238.09666666666666
          },
          {
            "id": 2842,
            "fundraiser_id": 2, 
            "contribution_date": "2022-04-23",
            "valid_thru": "2022-04-30",
            "amount": 100.0,
            "currency_iso": "SGD",
            "ofp_usd_est": 74.63
          },
          {
            "id": 2843,
            "fundraiser_id": 8888, 
            "contribution_date": "2023-01-28",
            "valid_thru": "2023-01-31",
            "amout": 20,
            "currency_iso": "USD",
            "ofp_usd_est": 20
          },
          {
            "id": 2844,
            "fundraiser_id": 8888, 
            "contribution_date": "2023-01-28",
            "valid_thru": "2023-02-28",
            "amout": 20,
            "currency_iso": "USD",
            "ofp_usd_est": 20
          }
        ]
      }
    ];
    return data;
  };

  const getData = async () => {
    try {
      setIsLoading(true);
      // Local testing
      if (testing==='1') {
        let thisRecord = sampleData();
        setRecord(thisRecord);
      }
      else {
        let response = await getRecordByUuidTemplate(uuid, prjTp, memberid);
        switch (response?.data?.statusCode) {
          case 200:
            let data = response.data.body;
            data.memberid_flag= (memberid != null);
            if (data?.member_id) {
              setRecord(data);
              logger.log('[MemberVerify] >>>', record);
            }
            setHasRec(true);
            break;
  
          default:
            setHasRec(false);
        }
      }
    } catch (err) {
      logger.log('[MemberVerify]Error at getRecordByUuidTemplate:', err);
    } finally {
      setIsLoading(false);
      setValidQuery(true);
      logger.log('Test: Record Found', uuid, prjTp, validQuery);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        alignContent: 'flex-start',
        textAlign: 'center',
        width: '100%',
        minHeight: '100vh',
        backgroundColor: '#E6E6E6',
        backgroundSize: 'cover',
      }}
    >
      <Stack
        spacing={2}
        paddingTop={2}
        sx={{ 
          width: { 
            sm: record?.transactions ? '0.7':'0.4',
            xs: '0.95' 
          },
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center',
          maxWidth: '1230px'
        }}
      >
        <Stack
          direction='row'
          spacing={5}
          sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', width: 'fit-content' }}
        >
          <CardMedia
            className={classes.headerLogo}
            component='img'
            image={MOD_LOGO}
            alt='MOD'
            onClick={() => openInNewTab('https://www.facebook.com/modNUG')}
          ></CardMedia>
          <CardMedia
            className={classes.headerLogo}
            component='img'
            image={PRF_LOGO}
            alt='PRF'
            onClick={() => openInNewTab('https://www.facebook.com/prf4pdf')}
          ></CardMedia>
          <CardMedia
            className={classes.headerLogo}
            component='img'
            image={PDF_LOGO}
            alt='PDF'
            sx={{
              height: '2.8rem'
            }}
            onClick={() => openInNewTab('https://www.facebook.com/prf4pdf')}
          ></CardMedia>
        </Stack>
        { record?.transactions &&  record.memberid_flag && 
          <Stack
            direction='row'
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '1' }}
          >
            <Paper
              sx={{
                padding:{ 
                  sm: '2rem',
                  xs: '1rem' 
                },
                borderRadius: { 
                  sm: '1.3rem',
                  xs: '0.8rem' 
                },
                width: '100%',
                backgroundColor: '#C7191A'
              }}
            >
              <Typography 
                sx={{
                  color: '#fff',
                  typography: { 
                    lg: 'h3',
                    md: 'h4',
                    sm: 'h5',
                    xs: 'subtitle1' 
                  },
                  fontWeight: {
                    sm: 'normal',
                    xs: 'bold'
                  }

                }}>
                ပြည်သူ့တော်လှန်ရေးထောက်ပို့တပ် - PRF
              </Typography>
            </Paper>
          </Stack>
        }
        { record?.transactions && record.memberid_flag && 
          <SupportCalendar data={record} />
        }
        <Stack
          direction='row'
          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '1' }}
        >
          {isLoading ? (
            <LoadingMember />
          ) : (
            hasRec ?
              (<MemberFound data={record} /> )
              :
              (<MemberNotFound />)
          )}
        </Stack>
        <Footer />
      </Stack>
    </Box>
  );
}
