import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  key_bold: {
    fontWeight: 900,
  },
  value_bold: {
    color: '#AF270A',
    fontWeight: 900,
  },
  value_normal: {
    color: '#AF270A',
    fontWeight: 700,
  },
}));

export function RecordFound(props) {
  const classes = useStyles();

  const formatTime = (value) => {
    const dateTime = new Date(value);
    let date = dateTime.getDate().toString().padStart(2, '0');
    let month = (dateTime.getMonth() + 1).toString().padStart(2, '0');
    let year = dateTime.getFullYear();
    const formattedTime = `${year}-${month}-${date}`;
    return value ? formattedTime : '';
  };

  return (
    <Stack spacing={2} padding={2}>
      <Stack direction='row' spacing={1} justifyContent='center' alignItems='baseline'>
        <Typography className={classes.key_bold} fontWeight='900'>
          Serial No:
        </Typography>
        <Typography className={classes.value_bold}>{props?.data[0]?.transaction_id}</Typography>
      </Stack>
      <Typography className={classes.value_normal}>
        {props?.data[0]?.reference1 ? props?.data[0]?.reference1 : `{props?.data[0]?.reference1}`}
      </Typography>
      <Typography>အတွက်</Typography>
      <Typography className={classes.value_normal}>
        {props.data[0]?.name ? props?.data[0]?.name : `{props?.data[0]?.name}`}
      </Typography>
      <Typography>က</Typography>
      <Typography className={classes.value_normal}>{`${
        props?.data[0]?.reference2 ? props?.data[0]?.reference2 : '{props?.data[0]?.reference2}'
      }`}</Typography>
      <Typography sx={{ fontSize: 'small' }}>ထောက်ပို့ကြောင်း အတည်ပြုပါသည်။</Typography>
      <Typography variant='overline' fontSize='0.6rem'>
        Issued Date: {formatTime(props?.data[0]?.transactiontime)}
      </Typography>
    </Stack>
  );
}
