import { Grid, Skeleton } from '@mui/material';

export function LoadingMember() {
  return (
    <Grid container p={2} spacing={2}>
      <Grid item xs={12} sm={6}>
        <Skeleton variant="rounded" width="100%" height={60} animation="wave" />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Skeleton variant="rounded" width="100%" height={60} animation="wave" />
      </Grid>
    </Grid>
  );
}
