// import NewReleasesIcon from '@mui/icons-material/NewReleases';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import EngineeringIcon from '@mui/icons-material/Engineering';

export function RecordNotFound() {
  return (
    <Stack sx={{ paddingTop:'2rem', alignContent:'center', alignItems:'center' }}>
      <Typography variant='subtitle1' sx={{ color: '#b20d0d', fontWeight: 800 }}>
        ဂုဏ်ပြုမှတ်တမ်းလွှာ မှတ်တမ်းတင်ထားခြင်းမရှိပါ။
      </Typography>
      {/* <EngineeringIcon sx={{ fontSize: '7rem', color: '#b20d0d' }} />
      <Typography variant='subtitle1' sx={{ color: '#b20d0d', fontWeight: 800 }}>
        ကာကွယ်ရေးဝန်ကြီးဌာန၏ ဂုဏ်ပြုမှတ်တမ်းလွှာ စစ်ဆေးသော verification system အား 
        လက်ရှိတွင် ပြင်ဆင်နေပါသဖြင့် ခေတ္တစောင့်ဆိုင်းပြီးမှ ပြန်လည်စစ်ဆေးကြပါရန် အကြောင်းကြားအပ်ပါသည်။
      </Typography> */}
    </Stack>
  );
}
