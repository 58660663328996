import { makeStyles } from '@mui/styles';
import {Stack, CardMedia, Typography, Box} from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import LanguageIcon from '@mui/icons-material/Language';
import EmailIcon from '@mui/icons-material/Email';

const useStyles = makeStyles(() => ({
  footer: {
    color: '#777',
    textAlign: 'center',
    position: 'inherit',
    bottom: '0',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    padding: '2rem',
    paddingTop: "3rem"
  },
  social: {
    color: '#777',
    cursor: 'pointer',
  },
}));

export function Footer() {
  const classes = useStyles();

  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noreferrer');
  };

  return (
    <Box className={classes.footer}>
      <Stack>
        <Stack direction='row' paddingLeft={1} spacing={1} justifyContent='center'>
          <Typography variant='caption' color='inherit'>
            verify.modnug &copy; {new Date().getFullYear()} All rights reserved.
          </Typography>
          <Stack direction='row' paddingLeft={1} spacing={1} justifyContent='center'>
            <FacebookIcon
              fontSize='inherit'
              className={classes.social}
              onClick={() => openInNewTab('https://www.facebook.com/modNUG')}
            />
            <LanguageIcon
              fontSize='inherit'
              className={classes.social}
              onClick={() => openInNewTab('https://mod.nugmyanmar.org/')}
            />
            <EmailIcon
              fontSize='inherit'
              className={classes.social}
              onClick={() => openInNewTab('mailto:mod@nugmyanmar.org')}
            />
          </Stack>
        </Stack>

        <Stack direction='row' paddingLeft={1} spacing={1} justifyContent='center'>
          <Typography variant='caption' color='inherit'>
          ပြည်သူ့တော်လှန်ရေးထောက်ပို့တပ် - PRF program is supported by OFP 
          </Typography>
          <img
            src="https://cdn.crphofp.org/generic/ofp/logos/ofp/ofp_logo-gray777.png"
            height="16" alt="ofp" 
            onClick={() => openInNewTab('https://www.facebook.com/crph.ofp.official')}
          />         
        </Stack>
      </Stack>
      
    </Box>
  );
}
