import { useLocation } from 'react-router-dom';
import { RecordNotFound } from '../components/RecordNotFound';
import { RecordFound } from '../components/RecordFound';
import { CertHeader } from '../components/CertHeader';
import { logger } from '@sentry/utils';
import { useEffect, useState } from 'react';
import { getRecordByUuidTemplate } from '../services/Api';
import { LoadingRecord } from '../components/LoadingRecord';
import { Footer } from '../components/Footer';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import BACKGROUND from '../static/placeholder/background.png';

export function VerifyPage() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const uuid = queryParams.get('uuid');
  const prjTp = queryParams.get('prjTp');
  const [isLoading, setIsLoading] = useState(true);
  const [record, setRecord] = useState([]);
  const [validQuery, setValidQuery] = useState(false);

  useEffect(() => {
    if (uuid && prjTp) {
      getData();
    } else {
      setValidQuery(false);
      setIsLoading(false);
    }

    logger.log('CertVerifyPage >>', uuid, prjTp, validQuery);
  }, [uuid, prjTp]);

  const getData = async () => {
    try {
      setIsLoading(true);

      let response = await getRecordByUuidTemplate(uuid, prjTp);
      let data = response.data.body;
      if (data) {
        setRecord(JSON.parse(data));
        logger.log('>>>', record);
      }
    } catch (err) {
      console.error('Error at getRecordByUuidTemplate', err);
    } finally {
      setIsLoading(false);
      setValidQuery(true);
      logger.log('Test:Record Found', uuid, prjTp, validQuery);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        width: '100%',
        minHeight: '100vh',
        backgroundColor: '#7d0812',
        backgroundImage: `url(${BACKGROUND})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      <Stack
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          width: '100%',
          height: 'auto',
          overflow: 'auto',
          paddingTop: '2rem',
        }}
      >
        <Paper
          sx={{
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
            border: `0.4rem solid ${!isLoading && record.length > 0 ? '#cca01b' : '#d96565'}`,
            backgroundColor: '#fcf7e8',
            width: '80vw',
            height: '70%',
            maxWidth: { xs: '60vw', md: '60vw' },
            minHeight: { xs: '60vh', md: '60vh' },
            padding: '2rem',
          }}
        >
          <Stack width='inherit' justifyContent='center' spacing={2}>
            <CertHeader />
            {isLoading ? (
              <LoadingRecord />
            ) : (
              <>{record.length > 0 && validQuery ? <RecordFound data={record} /> : <RecordNotFound />}</>
            )}
          </Stack>
        </Paper>
        <Footer />
      </Stack>
    </Box>
  );
}
