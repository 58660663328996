import CardMedia from '@mui/material/CardMedia';
import LOGO from '../static/stock/verify_page_title_logo.png';
import Stack from '@mui/material/Stack';

export function CertHeader() {
  return (
    <Stack direction='row' justifyContent='center' alignItems='center'>
      <CardMedia
        sx={{ width: '70vw', maxWidth: { xs: 200, md: 200 } }}
        component='img'
        image={LOGO}
        alt='Logo'
      ></CardMedia>
    </Stack>
  );
}
