import { 
  Stack, Paper, Typography, Grid, CardMedia 
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import { useEffect, useState } from 'react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Groups3Icon from '@mui/icons-material/Groups3';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import PRF_MEMBER_1 from '../../static/stock/prf-member-icon-1.png';
import PRF_MEMBER_2 from '../../static/stock/prf-member-icon-2.png';
import PRF_MEMBER_3 from '../../static/stock/prf-member-icon-3.png';

const PDFSupportAmount = 20;
const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

const useStyles = makeStyles(() => ({
  typographyStyle1: {
    color: '#A32738',
    fontSize: '1.4rem !important',
    wordWrap: 'break-word',
    width: '65vw',
  },

  disclaimer: {
    fontSize: '0.8rem !important',
    lineHeight: '1.4rem !important',
    color: 'grey',
  },
  key_bold: {
    fontWeight: 900,
    color: '#A32738',
  },
}));

const getID = (frid, id) => {
  if (frid && id) {
    let fr_id = String(frid).padStart(3, '0'),
    member_id = String(id).padStart(7, '0');
    return 'PRF - ' + fr_id + ' - ' + member_id;     
  }
  else 
    return '-';
};

const getMonth = (date) => {
  if (date) {
      let month = new Date(date).getMonth(),
      year = new Date(date).getFullYear();
    return monthNames[month].substring(0,3) + ' - ' + year;
  }
  else
    return '-';
};

export function MemberFound(props) {
  const [record, setRecord] = useState(null);
  const [showMember, setShowMember] = useState(false);
  const [showFullInfo, setShowFullInfo] = useState(false);

  useEffect(() => {
    if (props?.data) {
      parseData(props?.data);
    }
  },[props]);

  useEffect(() => {
    setShowMember(true);
    setShowFullInfo(record?.memberid_flag);
  },[record]);

  const parseData = (data) => {
    let t = data.transactions;
    if (t && t.length > 0) {
      data.earliest = t.reduce((a, b) => (a.valid_thru < b.valid_thru ? a : b));
      data.latest = t.reduce((a, b) => (a.valid_thru > b.valid_thru ? a : b));
      data.months = t.length;
      data.total = t.reduce((a, v) => a = a + v.ofp_usd_est,0).toFixed(2);
      data.pdf = Math.ceil(data.total / PDFSupportAmount);  
    }
    setRecord(data);
  }

  const SupportInfo = () => {
    if (!record || !record?.transactions) {
      return (<></>);
    }
    return (
      <Paper
        sx={{
          height: '100%',
          p:{ 
            sm: '2rem 2rem 0 2rem',
            xs: '1rem 1rem 0 1rem' 
          },
          borderRadius: '1.5rem',
          backgroundColor: '#000',
          color: '#FFF',
          textAlign: 'start',
          justifyItems: 'flex-start',
          alignItems: 'flex-start',
          alignContent: 'flex-start'
        }}
        key={record.member_id+'supportinfo'}
      >
        <Stack spacing={4}>
          <Stack direction={'row'} spacing={3}>
            <CardMedia 
              sx={{ 
                width:'auto',  
                height: {
                  sm: '5rem',
                  xs: '3.5rem'
                }, 
                objectFit: 'contain' 
              }}  component='img' image={PRF_MEMBER_3}></CardMedia>
            <Stack spacing={1}>
              <Typography sx={{ 
                  fontSize: {
                    sm: '105%',
                    xs: '85%'
                  }
                }}>
                စုစုပေါင်းထောက်ပို့ခဲ့သည့်လ
              </Typography>
              <Typography 
                sx={{ 
                  color: '#FFCD20',
                  typography: { 
                    sm: 'h5',
                    xs: 'h6' 
                  },                  
                }}>
                {record.months} လ
              </Typography>
            </Stack>
          </Stack>
          <Stack direction={'row'} spacing={3}>
            <CardMedia 
              sx={{ 
                width:'auto',  
                height: {
                  sm: '5rem',
                  xs: '3.5rem'
                }, 
                objectFit: 'contain' 
              }}  component='img' image={PRF_MEMBER_2}></CardMedia>
            <Stack spacing={1}>
              <Stack>
                <Typography sx={{ 
                  fontSize: {
                    sm: '105%',
                    xs: '85%'
                  }
                }}>
                  ထောက်ပို့ခဲ့သည့်လအတွင်း စုစုပေါင်းထောက်ပို့နိုင်ခဲ့သည့် PDF ရဲဘော်
                </Typography>                
              </Stack>
              <Typography 
                sx={{ 
                  color: '#FFCD20',
                  typography: { 
                    sm: 'h5',
                    xs: 'h6' 
                  },                  
                }}>
                {record.pdf} ဦး
              </Typography>
            </Stack>
          </Stack>
          <Stack direction={'row'} spacing={3}>
            <CardMedia 
              sx={{ 
                width:'auto',  
                height: {
                  sm: '5rem',
                  xs: '3.5rem'
                }, 
                objectFit: 'contain' 
              }} component='img' image={PRF_MEMBER_1}></CardMedia>
            <Stack spacing={1}>
              <Stack>
                <Typography sx={{ 
                  fontSize: {
                    sm: '105%',
                    xs: '85%'
                  }
                }}>
                  ထောက်ပို့ခဲ့သည့်လအတွင်း စုစုပေါင်းထောက်ပို့နိုင်ခဲ့သည့် 
                  ငွေအမောင့်
                </Typography>                
              </Stack>              
              <Typography 
                sx={{ 
                  color: '#FFCD20',
                  typography: { 
                    sm: 'h5',
                    xs: 'h6' 
                  },                  
                }}>
                EUSD {record.total}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Paper>
    );
  };


  const MemberInfo = () => {
    if (!record) {
      return (<></>);
    }
      let thisData = (props.memberid_flag) ?
      {
        'ထောက်ပို့တပ်သား'  : record.member_name,
        'ကိုယ်ပိုင်အမှတ်'  : getID(record.latest.fundraiser_id, record.member_id),
        'စတင်ထောက်ပို့ခဲ့သည့်လ'  : getMonth(record.earliest.valid_thru),
        'နောက်ဆုံးထောက်ပို့ခဲ့သည့်လ'  : getMonth(record.latest.valid_thru),
        'နောက်ဆုံးထောက်ပို့ခဲ့သည့်လ၏ ငွေအမောင့်'  : 'EUSD ' + record.latest.ofp_usd_est
      }:
      {
        'ထောက်ပို့တပ်သား'  : record.member_name,
        'ကိုယ်ပိုင်အမှတ်'  : getID(record.latest.fundraiser_id, record.member_id),
        'ထောက်ပို့သည့်လ'  : getMonth(record.latest.valid_thru)
      }
    let memberInformations = Object.entries(thisData).map(([label, value]) => {
      return (
        <Grid 
          container 
          sx={{ 
            textAlign: 'start',
            pb: {
              sm: 'auto',
              xs: '0.9rem'
            }
          }}
        >
          <Grid
            item
            xs={7}
            sx={{
            }}
            key={label}
          >
            <Typography 
              
              sx={{ 
                color: '#fff',
                lineHeight: {
                  sm: '2.5',
                  xs: 'auto'
                },
                fontSize: {
                  sm: '105%',
                  xs: '85%'
                }
              }}
            >
              {label}
            </Typography>
          </Grid>
          <Grid
            item
            xs={5}
            key={value}
          >
            <Typography 
              
              sx={{ 
                color: '#FFCD20',
                lineHeight: {
                  sm: '2.5',
                  xs: 'auto'
                },
                fontSize: {
                  sm: '105%',
                  xs: '85%'
                }
              }}
            >
              {value}
            </Typography>
          </Grid>
        </Grid>
      )
    });
    return (
      <Paper
        sx={{
          height: '100%',
          p:{ 
            sm: '2rem 2rem 0 2rem',
            xs: '1rem 1rem 0 1rem' 
          },
          borderRadius: '1.5rem',
          backgroundColor: '#000'
        }}
        key={record.member_id+'memberinfo'}
      >
        <Grid 
          container 
          sx={{ width: '100%' }}
        >
          <Grid
            item
            xs={12}
            sx={{ 
              pt: '0.6rem',
              pb: {
                sm: '1.8rem',
                xs: '0.9rem'
              }
            }}
            key={record.member_id+'title'}
          >
            <Typography sx={{ 
              color: '#fff',
              typography: { 
                lg: 'h5',
                md: 'h6',
                sm: 'subtitle1' 
              },
              fontWeight: {
                md: 'normal',
                sm: 'bold'
              }
            }}>
              ပြည်သူ့တော်လှန်ရေးထောက်ပို့တပ်သားအချက်အလက်
            </Typography>
          </Grid>
          {memberInformations}
          <Grid
            item
            xs={12}
            key={record.member_id+'disclaimer'}
          >
            <Stack
              direction={'row'}
              spacing={1}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                pt: {
                  sm: '1.8rem',
                  xs: '0'
                }
              }}
            >
              <CheckCircleOutlineIcon  sx={{ 
                fontSize: {
                  sm: '1.5rem',
                  xs: '1rem'
                }, 
                color: "#FFCD20" 
              }} />
              <Typography sx={{ 
                color: '#FFCD20',
                fontSize: {
                  sm: '100%',
                  xs: '70%'
                } 
              }}>
                အချက်အလက်မှန်ကန်ကြောင်း အသိအမှတ်ပြုအပ်ပါသည်။
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Paper>
    );
  };

  return (
    <Grid
      container
      spacing={{ xs: 4, sm: 6, md: 2 }}
    >
      {showMember &&  
        <Grid
          item
          xs={12}
          sm={12}
          md={record?.transactions && showFullInfo ? 6 : 12}
        >
          <MemberInfo />
        </Grid>
      }
      {showMember && showFullInfo && 
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
        >
          <SupportInfo />
        </Grid>
      }
    </Grid>
  );
}
