import { 
  Stack, Paper, Tooltip, Grid, Chip, Divider 
} from '@mui/material';
import { useEffect, useState } from 'react';
import { LoadingMember } from './LoadingMember';

const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
const currentYear = new Date().getFullYear();
const lowerLimitYear = 2021;

export function SupportCalendar(props) {
  const [record, setRecord] = useState(null);
  const [years, setYears] = useState([]);
  const [months, setMonths] = useState([]);
  const [activeYear, setActiveYear] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    populateDisplay();
    setActiveYear(currentYear);
    if (props?.data?.transactions) {
      parseData(props?.data?.transactions);
      setIsLoading(false);
    }
  },[props]);

  useEffect(() => {
    populateDisplay();
  },[record]);

  const populateDisplay = () => {
    parseYears();
    parseMonths();
  };

  const parseYears = () => {
    let latestYear = currentYear,
        y =[];
    if (record) {
      let latest = record.reduce((a, b) => (a.year > b.year ? a : b));
      latestYear = latest.year;
    }
    for (let i = lowerLimitYear; i <= latestYear; i ++) {
      y.push(i);
    }
    setYears(y);
  };

  const parseMonths = () => {
    setMonths(monthNames.map(d => d.substring(0,3)));
  };

  const parseData = (data) => {
    let thisData = data.map(d => {
      let y = new Date(d.valid_thru).getFullYear(),
          m = new Date(d.valid_thru).getMonth();
      return {
       'monthIndex': m,
       'month': monthNames[m],
       'year': y,
       'active': y === activeYear
      }
    });
    setRecord(thisData);
  };

  const updateRecord = (thisYear) => {
    if (!record) return;
    
  };

  const handleAction = (index) => {
    let y = years[index];
    setActiveYear(y);
    updateRecord(y);
  };

  const YearsDisplay = () => {
    if (!years) {
      return (<></>);
    }    
    let ele = years.map((y, index) => {
      let thisActive = false;
      if (y === activeYear) {
        thisActive = true;
      }
      return (
        <Grid
          item
          xs={4}
          sm={4}
          md={6}
          lg={4}
          xl={3}
          key={index}
        >
          <Chip
            key={y}
            size='large'
            label={y}
            component={Paper}
            elevation={1}
            sx={{
              fontSize: { 
                sm: '1.4rem',
                xs: '0.9rem' 
              },
              p: { 
                sm: '1.3rem 1.1rem',
                xs: '0.5rem' 
              },
              borderRadius: '1.5rem',
              color: thisActive ? '#000' : '#FFF',
              backgroundColor: thisActive ? '#FFCD20' : '#C6C6C6',
            }}
            onClick={() => handleAction(index)} 
          />
        </Grid>
      );
    });
    return ele;
  };


  const MonthsDisplay = () => {
    if (!months) {
      return (<></>);
    }
    let activeMonths = record.filter(a => a.year === activeYear).map(a => a.monthIndex);
    let ele = months.map((m, index) => {
      let thisActive = activeMonths.some(a => a === index);
      return (
        <Grid
          item
          xs={3}
          sm={3}
          md={4}
          lg={3}
          xl={2}
          key={index}
        >
          <Chip
            key={index}
            size='large'
            label={m}
            component={Paper}
            elevation={1}
            sx={{
              fontSize: { 
                sm: '140%',
                xs: '95%' 
              },
              p: { 
                sm: '1.3rem 0.4rem',
                xs: '0.1rem' 
              },
              borderRadius: '1.5rem',
              color: thisActive ? '#fff' : '#C71A1A',
              backgroundColor: thisActive ? '#C71A1A' : '#000',
              border: '1px solid #C71A1A'
            }}
          />
        </Grid>
      );
    });
    return ele;
  };

  return (
    <Stack
      direction='row'
      sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'flex-start',
        alignContent: 'flex-start',
        width: '1' 
      }}
    >
    {isLoading ? (
      <LoadingMember />
    ) : (
      <Paper
        sx={{
          padding: {
            md: '2rem',
            sm: '1rem',
            xs: '1rem'
          },
          borderRadius: '1.5rem',
          backgroundColor: '#fff',
          width: '100%'
        }}
      >
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            sx={{
              borderRight: {
                md: '2px solid #999',
                sm: 'none'
              },
              borderBottom: {
                sm: '2px solid #999',
                xs: '2px solid #999',
                md: 'none'
              },
              pb: {
                xs: '1.2rem',
                sm: '1.2rem',
                md: '0.5rem'
              }
            }}            
          >
            <Grid
              container
              spacing={2}
            >              
              <YearsDisplay />
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
          >
            <Grid
              container
              spacing={2}
            >
              <MonthsDisplay />
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    )}
    </Stack>
  );
}
