import { Route, Routes } from 'react-router-dom';
import { LandingPage } from './pages/LandingPage';
import { VerifyPage } from './pages/VerifyPage';
import { MemberVerifyPage } from './pages/MemberVerifyPage';

function App() {
  return (
    <>
      <Routes>
        <Route path='' element={<LandingPage />} />
        <Route path='/record' element={<VerifyPage />} />
        <Route path='/member' element={<MemberVerifyPage />} />
      </Routes>
    </>
  );
}

export default App;
