import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_ApiServiceBaseURL;
axios.defaults.headers.common = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

// export const getOfpUrl = () => {
//   return 'https://verify.crphofp.org';
// };

// export const getNugModUrl = () => {
//   return 'http://verify.crphofp.org';
// };

export const getRecordByUuidTemplate = (uuid, prjTp, memberId) => {
  let params = 'prjTp='+ prjTp
        + (memberId ? '&mbid='+memberId :'') 
        + '&uuid='+ uuid;
  return axios.get(`getrec?${params}`);
};

export const openInNewTab = (url) => {
  window.open(url, '_blank', 'noreferrer');
};

// export const getRecordByUuidTemplateUAT = (uuid, prjTp) => {
//   return axios.get(`/getrec?prjTp=${prjTp}&uuid=${uuid}`);
// };
