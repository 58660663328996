import { 
  Grid, Paper, Stack, Typography 
} from '@mui/material';

export function MemberNotFound() {
  return (
    <Paper
      sx={{
        padding:{ 
          sm: '2rem',
          xs: '1rem' 
        },
        borderRadius: { 
          sm: '1.3rem',
          xs: '0.8rem' 
        },
        width: '100%',
        backgroundColor: '#000'
      }}
    >
      <Stack 
        sx={{ 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center',
          alignContent: 'center',
          width: '1' 
        }}
      >
        <Typography variant='h5' style={{ color: '#fff' }}>
          ဤ QR code နှင့် ထောက်ပို့တပ်သား မှတ်တမ်းတင်ထားခြင်းမရှိပါ။
        </Typography>
      </Stack>
    </Paper>
  );
}
